<template>
  <b-card
    class="card-tiny-line-stats"
    body-class="pb-50"
  >
    <vue-apex-charts
      ref="circleChart"
      :options="{...earningsChart.chartOptions, labels}"
      :series="series"
      :height="height"
    />

    <b-row>
      <b-col
        v-if="customTotal"
        cols="12"
        class="d-flex justify-content-center font-weight-bolder"
      >
        <span class="label">Total Sales:</span>
        <span class="content ml-0-5">{{ customTotalValue }}</span>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import { BCard } from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'

export default {
  components: {
    BCard,
    VueApexCharts,
  },
  props: {
    labels: {
      type: Array,
      default() {
        return []
      },
    },
    series: {
      type: Array,
      default() {
        return []
      },
    },
    colors: {
      type: Array,
      default() {
        return []
      },
    },
    title: {
      type: String,
      default() {
        return 'Total'
      },
    },
    customTotal: {
      type: Boolean,
      default() {
        return false
      },
    },
    customTotalValue: {
      type: Number,
      default() {
        return 0
      },
    },
    actualNumbers: {
      type: Array,
      default() {
        return null
      },
    },
    height: {
      type: Number,
      default() {
        return 300
      },
    },
  },
  data() {
    return {
      show: false,
      chartLoaded: false,
      earningsChart: {
        series: [],
        chartOptions: {
          chart: {
            width: '100%',
            type: 'donut',
          },
          states: {
            hover: {
              filter: {
                type: 'none',
              },
            },
          },
          titles: this.titles ? this.titles : null,
          labels: this.labels,
          customTotalValue: this.customTotal && this.customTotalValue ? this.customTotalValue : null,
          customTotal: this.customTotal ? this.customTotal : null,
          actualNumbers: this.actualNumbers ? this.actualNumbers : null,
          total: this.total ? this.total : null,
          colors: this.colors,
          plotOptions: {
            pie: {
              donut: {
                labels: {
                  show: true,
                  name: {
                    show: true,
                    fontSize: '22px',
                    fontFamily: 'Rubik',
                    color: '#dfsda',
                    offsetY: -10,
                  },
                  value: {
                    show: true,
                    fontSize: '16px',
                    fontFamily: 'Helvetica, Arial, sans-serif',
                    color: undefined,
                    offsetY: 0,
                    formatter(val, opts) {
                      if (opts.config.customTotal) {
                        return `${(parseFloat(val)).toFixed(2)}% from total issues`
                      }
                      return val
                    },
                  },
                  total: {
                    show: true,
                    label: this.title,
                    color: '#373d3f',
                    formatter(w) {
                      if (w.config.customTotal) {
                        const result = w.config.actualNumbers.reduce((a, b) => a + b, 0)
                        return `${result} Issue`
                      }
                      const result = w.config.series.reduce((a, b) => a + b, 0)
                      return result || 0
                    },
                  },
                },
              },
            },
          },
          dataLabels: {
            enabled: false,
            formatter(val, opts) {
              const name = opts.w.globals.labels[opts.seriesIndex]
              return [name, `${val.toFixed(1)}%`]
            },
          },
          tooltip: {
            enabled: true,
            y: {
              formatter: this.customTotal ? (value, opts) => {
                if (opts.config.actualNumbers && opts.config.actualNumbers[opts.seriesIndex]) {
                  return `${((opts.config.actualNumbers[opts.seriesIndex] / opts.config.customTotalValue) * 100).toFixed(2)}% from total sales`
                }
                return `${((value / opts.config.customTotalValue) * 100).toFixed(2)}%`
              } : null,
            },
          },
          legend: {
            show: false,
          },
          responsive: [
            {
              breakpoint: 1400,
              options: {
                chart: {
                  height: 250,
                },
              },
            },
            {
              breakpoint: 1000,
              options: {
                chart: {
                  height: 200,
                },
              },
            },
            {
              breakpoint: 991,
              options: {
                chart: {
                  height: 300,
                },
              },
            },
          ],
        },
      },
    }
  },
  updated() {
    this.$refs.circleChart.chart.updateOptions({
      plotOptions: {
        pie: {
          donut: {
            labels: {
              total: {
                show: true,
                label: this.title,
                color: '#373d3f',
                formatter: () => {
                  const result = this.series.reduce((a, b) => a + b, 0)
                  return result || 0
                },
              },
            },
          },
        },
      },
    })
  },
}
</script>
<style lang="scss">
 .card-tiny-line-stats{
  .ml-0-5 {
    margin-left: 0.5rem !important;
  }
 }
</style>
